import React, { useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, TextField, IconButton,Link as Links,
    FormControl,Select,MenuItem,Typography, Button, Grid, Container,Alert, Snackbar, Checkbox, RadioGroup, FormControlLabel, Radio, Autocomplete  } from '@mui/material';
    import VisibilityIcon from '@mui/icons-material/Visibility';
    import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
    import InputAdornment from '@mui/material/InputAdornment';
    // import { message } from "antd";
    import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import axiosInstance from 'axios';
import { Link } from 'react-router-dom';
import "./Login.css";
import axiosAPI from '../../../api/axiosAPI';
// import bcrypt from 'bcryptjs';

const axios = axiosInstance.create({
     baseURL: axiosAPI(),
  headers: {
      'Content-Type': 'application/json'
  },
});

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

const countries: readonly CountryType[] = [

  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' }
];


function LoginForm() {

  // const salt = bcrypt.genSaltSync(10)
  
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    
        // email: 'master@gmail.com',
        // password: 'master',
        country: '',
      });


      const [errors, setErrors] = useState({
        email: '',
        password: '',
        country: '',
      });

      const [showPassword, setShowPassword] = useState(false);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const [open, setOpen] = React.useState(false);
      const [openEmailPswd, setOpenEmailPswd] = React.useState(false);

      const [openStatus, setOpenStatus] = React.useState(false);
      // const [messageApi, contextHolder] = message.useMessage();


      const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

        setErrors((prevState) => ({ ...prevState, [name]: '' }));
      };

      const handleCountryChange = (event: any, value: CountryType | null) => {
        setFormData((prevData) => ({
          ...prevData,
          country: value ? value.code : '',
        }));
        setErrors((prevState) => ({ ...prevState, country: '' }));
      };
    

    
      const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };


      const validateForm = () => {
        const newErrors: any = {};
        if (!formData.email) {
          newErrors.email = 'Please enter an email.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Please enter a valid email.';
        }
        if (!formData.password) {
          newErrors.password = 'Please enter a password.';
        }

        if(axiosAPI() === 'http://124.29.248.126:8089/' && axiosAPI() === 'https://api.yallabestoffers.com/')
          {
          newErrors.country = 'Please select a country.';
       console.log("runing");
          }
        return newErrors;
      };

      const handleSubmit = async  () => {

//         const hashedPassword = bcrypt.hashSync(formData.password, salt) // hash created previously created upon sign up
// console.log(hashedPassword)

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

const res = {
  email:formData.email,
  password:formData.password
}

let country;
  if(axiosAPI() !== 'http://124.29.248.126:8089/' &&
   axiosAPI() !== 'https://api.yallabestoffers.com/')  {
      country = "PK"

   }else{
country = formData.country;
   }
        try {
          
          const response = await axios.post(
            "/api/user/login",
            res,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Country-Key": country,
              },
              //  withCredentials: true, // Move this option to the config object
            }
          );
      
          const { userId, merchant, role, status, token} = response.data;
   
          if(status == 'Y'){
              localStorage.setItem("userId",userId);
              if(axiosAPI() !== 'http://124.29.248.126:8089/' && axiosAPI() !== 'https://api.yallabestoffers.com/')
                {
              localStorage.setItem("X-Country-Key","PK");
                }else{
                  localStorage.setItem("X-Country-Key",formData.country);
                }
              if(merchant){

                localStorage.setItem("MerchantID",merchant.merchantId);
              }
              localStorage.setItem("UserRoleID", role.id);
              localStorage.setItem("UserRoleName", role.name);
              localStorage.setItem('token', token);
              // localStorage.setItem("csrfToken", csrfToken);
           
              dispatch(getUserDetails('00'))
      
              setOpen(true);
              setTimeout(()=>{
                navigate('/dashboard')
              },2000)
            }else{
              setOpenStatus(true);
              setTimeout(()=>{
                handleClose();
              },3000)

            }
          
        } catch (err: any) {
          if (err.response && err.response.data) {
            setOpen(true);
            setOpenEmailPswd(true);
            setTimeout(()=>{
              handleClose();
            },1000)
        
          } else {
            console.error("Error:", err);
        
          }
        }
      
      }


      const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
        setOpenStatus(false);
        setOpenEmailPswd(false);
      };

  return (
   <>

<Grid container spacing={2} >
<Grid item sm={6} md={6} lg={6} style={{ backgroundImage: `url("assets/Auth/LoginImage.png")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100vh' }}>

{/* <Grid item sm={6} md={6} lg={6} style={{backgroundImage: `url("assets/Auth/LoginImage.png")`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat',height:'667px'}}> */}
{/* <LazyLoadImage 
        src="assets/Images/LoginImage.png"   alt="Login Image"
      /> */}
    </Grid>

    <Grid item sm={6} md={6} lg={6} >
    {/* <div style={{marginLeft:'80%',marginTop:'25px',color:'blue'}}>English</div> */}
   
    <Typography className='welcome-text' variant="h4" gutterBottom sx={{ fontWeight: 'bold', marginTop: '17%',marginLeft:'17%' }}>
        Welcome
      </Typography>
     
    <Grid container spacing={2} style={{marginTop:'20px'}}>
    <Grid item sm={2} md={2} lg={2} >
</Grid>
    <Grid item sm={8} md={8} lg={8} >

      {/* <form id='login-form' > */}
  <div >
    <Typography variant="body2" sx={{ textAlign: 'left'}}>
      Username
    </Typography>
    <TextField 
      value={formData.email}
      placeholder='Enter email ID'
      onChange={handleChange}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="username"
      name="email"
      className='field'
      autoComplete="username"
      autoFocus
      error={!!errors.email}
      helperText={errors.email}
    />
  </div>

  <div>
    <Typography variant="body2" sx={{ textAlign: 'left', marginBottom: '0rem' }}>
      Password
    </Typography>
    <TextField
  value={formData.password}
  onChange={handleChange}
  error={!!errors.password}
  helperText={errors.password}
  placeholder='Enter password'
  variant="outlined"
  margin="normal"
  required
  fullWidth
  name="password"
  type={showPassword ? 'text' : 'password'} // Toggle between text and password type
  id="password"
  autoComplete="current-password"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={handleTogglePassword}
          size="large"
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
  </div>

  {
  (axiosAPI() === 'http://124.29.248.126:8089/' || 
   axiosAPI() === 'https://api.yallabestoffers.com/') && (
  <div>
    <Typography variant="body2" sx={{ textAlign: 'left', marginBottom: '0.5rem' }}>
      Country
    </Typography>
  <Autocomplete
      id="country-select-demo"
      // sx={{ width: 300 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      onChange={handleCountryChange}
      
      renderInput={(params) => (
        <TextField
        {...params}
        error={!!errors.country}
        helperText={errors.country}
        placeholder='Select Country'
          // label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', 
          }}
        />
      )}
    />

    </div> 

  )}



  <div className="login-card">
  
  {/* <Link to={`/ResetPswd/${formData.email}`} style={{ textAlign: 'left', marginBottom: '1rem',marginRight:'-25%'}}>
    Reset Password
    </Link> */}

    <Link to="/ForgotPswd" style={{ textAlign: 'right', marginBottom: '1rem',marginLeft:'72%' }}>
    {/* Can’t sign in? */}
    Forgot Password
    </Link>
  </div>

  <Button 
    type="submit" 
    fullWidth
    variant="contained"
    // color="primary"
    sx={{ mt: 2 ,backgroundColor:'blue'}}
    onClick={() => handleSubmit()}
  >
    Login
  </Button>
{/* </form> */}
</Grid>
    </Grid>

    <Grid container spacing={2} style={{marginTop:'70px'}}>
    <Grid item sm={2} md={2} lg={2} >
      </Grid>
    <Grid item sm={8} md={8} lg={8} >
      {/* <Typography gutterBottom sx={{ marginTop: '70px' ,textAlign:'center'}}>
      Not registered yet? <Links>Enroll/Activate here</Links>
      </Typography> */}

</Grid>
    </Grid>
    </Grid>
   
    </Grid>

    <Snackbar
  open={open}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Logged In SuccessFully!
  </Alert>
</Snackbar>

<Snackbar
  open={openEmailPswd}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="error"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Invalid email or password!
  </Alert>
</Snackbar>


<Snackbar
  open={openStatus}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="warning"
    variant="filled"
    sx={{ width: '100%' }}
  >
    This account is suspended. Try another account!
  </Alert>
</Snackbar>

   </>
  );
}

export default LoginForm;