const emailTemplate = (email,country,axiosAPI) => `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Forgot Password</title>
    <style>
        body {
        color: #2F1329;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
            margin: 0;
            padding: 0;
            background-color: #ffffff;
        }
        .email-container {
            width: 100%;
            margin: auto;
            background-color: #ffffff;
        }
        .header {
            text-align: right;
            padding: 20px;
            width: 300px;
            height: 300px;
            padding-left: 150px;
        }
        .content {
            padding: 20px 60px;
        }
        .content h1 {
            color: #2F1329;
        }
        .content p {
            color: #2F1329;
            line-height: 1.5;
        }
        .content strong {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #2F1329;
        }
        .application-process {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
        }
        .application-process div {
            width: 23%;
            color: #4B3049 !important;
        }
        .application-details {
            margin: 20px 0;
        }
        .application-details ul {
            padding: 0;
        }
        .application-details li {
            color: #4B3049;
            margin-bottom: 10px;
        }
        .application-details li strong {
            color: #4B3049;
        }
        .footer {
            background: #FAF6F9;
            padding: 20px;
            text-align: center;
            color: #2F1329;
        }
        .cta {
            display: inline-block;
            padding: 10px 20px;
            background-color: #4CAF50;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            margin-top: 20px;
        }
        .social-icons img {
            max-width: 30px;
            margin: 0 10px;
        }
        @media screen and (max-width: 600px) {
            .content {
                padding: 10px;
            }
            .application-details li {
                font-size: 14px;
            }
        }
    </style>
</head>
<body>
    <div class="email-container">
        <div class="header">
            <div><img src="cid:logo01" alt="Bazaar Best Offers" style="width: 300px; height: auto;"></div>
            <div><img src="cid:lastStep1" alt="Celebration" style="margin-top: 20px; width: 300px; height: auto;"></div>
        </div>
        <div class="content">
            <h2>Dear Customer,</h2>
            <p>To change your password, please click on the link below:</p>
         
           <a href="${axiosAPI}#/ResetPswd/${email}&country:${country}" style="color: #5B7194;">https://api.Bazaarbestoffers.com/</a>
         
            <p>Please feel free to reach out to us incase you require any further clarity or need help with the activation.</p>
            <p>Looking forward to serve you.</p>
         
            <p>Best Regards,<br>KT Bank</p>
            <br>
            <br>
            <div class="footer">
     
            <p>We hate to see you go, but if you must <a href="#" style="color: #5B7194;">unsubscribe</a></p>
            <p>Copyright&copy; 2024 Bazaar Best Offers Powered By KT Bank, All rights reserved</p>
        </div>
    </div>
</body>
</html>
`;
 
export default emailTemplate;